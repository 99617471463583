import request from "@/utils/request";

//获取数据列表
export function getList(params) {
  return request({
    url: "/happyroom/patient/",
    method: "get",
    params,
  });
}
